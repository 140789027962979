import { useRouter } from "next/router";

import { captureException } from "@sentry/nextjs";
import axios, { AxiosHeaders } from "axios";

import { useAuth } from "@/components/auth/AuthContext";

import { getRegionBySlug } from "../regions/regionsConfig";

export const axiosConfig = {
  headers: {
    Accept: "application/json",
  },
};

const useAxiosInstance = () => {
  const router = useRouter();
  const { region, lang, t } = router.query;

  const currentRegion = getRegionBySlug(region as string);

  const { token: accessToken, logout, refreshToken, impersonate } = useAuth();

  const axiosInstance = axios.create(axiosConfig);

  const addTokenToQueryParams = (
    url: string | string[] | undefined,
    token: string | null,
  ) => {
    const prefix = url?.indexOf("?") !== -1 ? "&" : "?";
    return `${prefix}t=${token}`;
  };

  axiosInstance.interceptors.request.use(
    async (config) => {
      addTokenToQueryParams(config.url, t as string);

      const headers: Record<string, string> = {};

      headers["Accept-Language"] = String(lang);

      if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

      if (impersonate)
        config.params = { ...config.params, _switch_user: impersonate };

      return {
        ...config,
        baseURL:
          currentRegion?.apiUrl || process.env.NEXT_PUBLIC_DASHBOARD_API_NL_URL,
        ...(t
          ? {
              url: `${config.url}${addTokenToQueryParams(config.url, t as string)}`,
            }
          : null),
        headers: new AxiosHeaders({
          ...config.headers,
          ...headers,
        }),
      };
    },
    (error) => {
      // Do something with request error
      captureException(error);
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error?.response?.status === 401 &&
        error?.response?.data?.message === "Expired JWT Token" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        await refreshToken();
        return axiosInstance(originalRequest);
      }

      if (
        error?.response?.status === 401 &&
        error?.response?.data?.identifier === "INVALID_CREDENTIALS"
      )
        return logout();

      return Promise.reject(error);
    },
  );

  return {
    axiosInstance,
  };
};

export default useAxiosInstance;
