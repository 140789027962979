import { captureException } from "@sentry/nextjs";
import enGBLocale from "date-fns/locale/en-GB/index";
import frLocale from "date-fns/locale/fr/index";
import nlLocale from "date-fns/locale/nl";
import nlBELocale from "date-fns/locale/nl-BE";

import { type ILDBooleanFlags } from "../../types";

export type translationKey =
  | "languages.nl_NL"
  | "languages.nl_BE"
  | "languages.fr_FR"
  | "languages.en_GB";

export interface ILanguage {
  id: string;
  i18n: string;
  enabled?: boolean;
  enabledWithflags?: (flags?: ILDBooleanFlags) => boolean | undefined;
  serverKey: "fr_FR" | "nl_NL" | "nl_BE" | "en_GB";
  supportEmail: string;
  translationKey: translationKey;
  defaultLanguage: boolean;
  dateFnsLocale: Locale;
}

export const languages: ILanguage[] = [
  {
    id: "nl",
    i18n: "nl-NL",
    serverKey: "nl_NL", // Needed for language switch ServerSide
    translationKey: "languages.nl_NL",
    supportEmail: "salessupport@youngones.com",
    defaultLanguage: false,
    enabled: true,
    dateFnsLocale: nlLocale,
  },
  {
    id: "be",
    i18n: "nl-BE",
    serverKey: "nl_BE", // Needed for language switch ServerSide
    translationKey: "languages.nl_BE",
    supportEmail: "sales.be@youngones.com",
    defaultLanguage: false,
    enabled: true,
    dateFnsLocale: nlBELocale,
  },
  {
    id: "en",
    i18n: "en-GB",
    serverKey: "en_GB", // Needed for language switch ServerSide
    translationKey: "languages.en_GB",
    supportEmail: "uk@youngones.works",
    defaultLanguage: true,
    enabled: true,
    dateFnsLocale: enGBLocale,
  },
  {
    id: "fr",
    i18n: "fr-FR",
    serverKey: "fr_FR",
    translationKey: "languages.fr_FR",
    supportEmail: "support.fr@youngones.com",
    defaultLanguage: false,
    enabled: true,
    dateFnsLocale: frLocale,
  },
];

export const getLanguages = () => languages.filter((lang) => lang.enabled);

export const defaultLanguage = languages.find((lang) => lang.defaultLanguage);

export const getLanguageById = (id: string) => {
  const language = languages.find((lang) => lang.id === id);

  if (!language || !language.enabled)
    captureException(`languages.ts: Unsupported language: ${id}`);

  if (!language?.enabled) return null;
  else return language;
};

export const useGetLanguageByKey = () => {
  const getLanguageByKey = (key: string) => {
    if (!key) return;
    return getLanguages().find((lang) => lang.i18n === key);
  };

  return getLanguageByKey;
};

export const useGetLanguageByServerKey = () => {
  const getLanguageByServerKey = (key: string) => {
    if (!key) return;

    return getLanguages().find((lang) => lang.serverKey === key);
  };

  return getLanguageByServerKey;
};
