import { type TFunction } from "next-i18next";

import * as Yup from "yup";

const loginSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("login.validation.email_invalid"))
      .required(t("login.validation.email_required")),
    password: Yup.string().required(t("login.validation.password_required")),
    rememberMe: Yup.boolean().optional(),
  });

export default loginSchema;
