import React, {
  type ChangeEvent,
  type FocusEvent,
  forwardRef,
  type KeyboardEvent,
  type ReactNode,
} from "react";

import clsx from "clsx";

interface NewTextInputProps {
  rightSection?: ReactNode;
  leftSection?: ReactNode;
  label?: ReactNode;
  placeholder?: string;
  defaultValue?: string;
  className?: string;
  min?: string | undefined | number;
  max?: string | undefined | number;
  error?: string;
  showRightSection?: boolean;
  id?: string;
  step?: string;
  autoFocus?: boolean;
  type?: string;
  name?: string;
  value?: string | undefined | number;
  disabled?: boolean;
  maxLength?: undefined | number;
  autoComplete?: string;
  enablePasswordManagerAutofill?: boolean;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const NewTextInput = forwardRef<HTMLInputElement, NewTextInputProps>(
  (
    {
      rightSection,
      leftSection,
      defaultValue,
      placeholder,
      className,
      error,
      name,
      disabled,
      autoFocus,
      label,
      maxLength,
      enablePasswordManagerAutofill = false,
      onChange,
      onBlur,
      onKeyDown,
      id,
      min = "0",
      max,
      autoComplete,
      showRightSection = true,
      step,
      value,
      type,
      ...props
    },
    ref,
  ) => (
    <div className="newTextInputWrapper relative">
      {label && (
        <label className="mb-2 flex items-center gap-1 text-xs font-medium text-black">
          {label}
        </label>
      )}

      {leftSection && (
        <div className="absolute bottom-0 left-0 flex h-10 w-12 content-center items-center justify-center align-middle text-sm font-medium text-black">
          {leftSection}
        </div>
      )}

      <input
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        type={type || "text"}
        id={id}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        min={min}
        max={max}
        value={value}
        autoFocus={autoFocus}
        step={step}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        className={clsx(
          `h-10 w-full rounded-[4px] border border-gray-200 bg-gray-50 px-3 text-sm text-gray-900 outline-none selection:bg-turquoise-200 focus:border-turquoise-500 focus:ring-turquoise-500 active:border-turquoise-500 active:ring-turquoise-500 ${rightSection && "pr-12"} ${leftSection && "pl-12"} ${
            error &&
            `border-red-500 text-red-500 selection:bg-red-200 focus:border-red-500 focus:ring-red-500 focus:placeholder:text-red-500 active:border-red-500 active:ring-red-500`
          }`,
          className,
        )}
        data-lpignore={enablePasswordManagerAutofill ? undefined : "true"} // lastpass
        data-1p-ignore={enablePasswordManagerAutofill ? undefined : "true"} // 1Password
        ref={ref}
        {...props}
      />
      {rightSection && showRightSection && (
        <div className="absolute bottom-0 right-0 top-0 flex w-12 content-center items-center justify-center align-middle text-sm font-medium text-black">
          {rightSection}
        </div>
      )}
      {error && <div className="mt-2 text-xs text-red-500">{error}</div>}
    </div>
  ),
);
