import { type MutationFunction, useMutation } from "react-query";
import { useRouter } from "next/router";

import axios, { type AxiosResponse } from "axios";

import { useAuth } from "@/components/auth/AuthContext";
import { setRefreshToken, setToken } from "@/utils/authentication/authHelpers";
import apiRoutes from "@/utils/constants/apiRoutes";
import { axiosConfig } from "@/utils/hooks/useAxiosInstance";
import { getRegionBySlug } from "@/utils/regions/regionsConfig";

interface ILoginResponse {
  token: string;
  refresh_token: string;
}

interface IUserCredentials {
  username: string;
  password: string;
  rememberMe: boolean;
}

export function useLogin() {
  const router = useRouter();
  const { setToken: setTokenInAuth } = useAuth();

  const regionConfig = getRegionBySlug(`${router?.query?.region}` ?? "nl");

  let rememberMe1: boolean;

  const loginMutationFn: MutationFunction<
    AxiosResponse<ILoginResponse>,
    IUserCredentials
  > = ({ username, password, rememberMe }) => {
    rememberMe1 = rememberMe;

    return axios.post(
      `${regionConfig?.apiUrl}${apiRoutes.login}`,
      { username, password },
      axiosConfig,
    );
  };
  return useMutation(loginMutationFn, {
    onSuccess: (response) => {
      const { token, refresh_token } = response.data;

      setTokenInAuth(token);
      setToken(token);

      if (rememberMe1) setRefreshToken(refresh_token);
    },
  });
}
