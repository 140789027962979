import React, { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "next-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEyeCheck, IconEyeOff } from "@tabler/icons-react";
import { isAxiosError } from "axios";

import { Alert, Button, Checkbox, LinkText } from "@/components/ui";
import { getPageRoute } from "@/utils/constants/pageRoutes";
import { ERRORS_IDENTIFIERS } from "@/utils/enums";
import { removeRegion } from "@/utils/regions/regionsConfig";

import { NewTextInput } from "../ui/NewTextInput/NewTextInput";

import loginSchema from "./utils/loginSchema";

import { useLogin } from "@/services/auth/useLogin";
import { useReVerify } from "@/services/auth/useReVerify";

interface ILoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface IErrorResponse {
  response: {
    data: {
      identifier: string;
      message: string;
      hash?: string;
    };
  };
}

const LoginForm = () => {
  const { t } = useTranslation();

  const [error, setError] = useState<string | null>(null);
  const [hash, setHash] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [visible, { toggle }] = useDisclosure(true);

  const { mutateAsync: login } = useLogin();

  const router = useRouter();
  const region = router?.query?.region || "nl";
  const lang = router?.query?.lang || "nl-NL";

  const { mutateAsync: reVerify } = useReVerify();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(loginSchema(t)),
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  });

  const submit: SubmitHandler<ILoginFormValues> = async (values) => {
    setLoading(true);

    try {
      setError(null);

      await login({
        username: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      });

      const encodedTargetPath = (router.query.targetPath as string) ?? null;
      const decodedTargetPath = encodedTargetPath
        ? decodeURIComponent(encodedTargetPath)
        : null;

      const targetPath =
        decodedTargetPath?.substring(0, 1) === "/" && router.isReady
          ? decodedTargetPath
          : getPageRoute(
              "gigsOpen",
              String(router?.query?.region) || "nl",
              String(router.query?.lang) || "nl-NL",
            );

      await router.push(targetPath);
      setLoading(false);
    } catch (err: unknown) {
      if (err instanceof Error && isAxiosError(err)) {
        const error = err as IErrorResponse;
        if (
          error.response.data.identifier ===
          ERRORS_IDENTIFIERS.CLIENT_NOT_VERIFIED
        ) {
          setError(t("login.validation.userNotVerified").toString());
          setHash(error.response.data.hash);
        } else {
          setError(
            error.response.data.message === "Invalid credentials."
              ? t("login.validation.credentials_invalid").toString()
              : t("globals.error").toString(),
          );
        }
      }

      setLoading(false);
    }
  };

  return (
    <>
      <div data-testid="login">
        <form onSubmit={handleSubmit(submit)} className="flex flex-col gap-6">
          {error && (
            <Alert
              show={!!error}
              plain
              message={
                error === "login.validation.userNotVerified" ? (
                  <Trans i18nKey="login.validation.userNotVerified">
                    Je account is nog niet geactiveerd. Klik
                    <span
                      className="cursor-pointer text-accentBlue no-underline hover:underline"
                      onClick={() => reVerify({ hash: hash ?? "undefined" })}
                    >
                      hier
                    </span>
                    om de verificatie mail opnieuw te versturen.
                  </Trans>
                ) : (
                  error && error
                )
              }
              type="error"
            />
          )}

          <NewTextInput
            {...register("email")}
            id="email"
            autoComplete="email"
            label={t("globals.emailAddress")}
            error={errors?.email?.message ? errors.email.message : undefined}
          />
          <div>
            <PasswordInput
              {...register("password")}
              id="password"
              type={visible ? "password" : "text"}
              autoComplete="current-password"
              onVisibilityChange={toggle}
              classNames={{ input: "h-full", error: "mt-2 text-xs" }}
              label={t("globals.password.default")}
              visibilityToggleIcon={() =>
                visible ? (
                  <IconEyeOff
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                ) : (
                  <IconEyeCheck
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                )
              }
              error={
                errors?.password?.message
                  ? errors?.password?.message ?? ""
                  : undefined
              }
            />
          </div>
          <div className="flex justify-between">
            <Checkbox
              {...register("rememberMe")}
              label={t("login.keepData")}
              size="xs"
            />
            <LinkText
              // pathName={getPageRoute("forgotPassword", String(region), String(lang))}
              pathName={`/${region}/${lang}/forgot-password`}
              classNames="no-underline hover:underline"
            >
              {t("login.forgotPassword")}
            </LinkText>
          </div>
          <Button
            type="submit"
            disabled={isSubmitting}
            block
            isLoading={loading}
          >
            {t("login.button")}
          </Button>
        </form>
      </div>
      <div className="mt-8 flex flex-col justify-center gap-4">
        <p className="m-0 text-center text-xs">
          <Trans i18nKey="login.registerV2" count={2}>
            Nog geen opdrachtgevers account?
            <LinkText
              pathName={getPageRoute("register", String(region), String(lang))}
              classNames="no-underline hover:underline"
            >
              Registreer nu
            </LinkText>
            .
          </Trans>
        </p>
        <UnstyledButton
          variant="custom"
          onClick={async (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
          ) => {
            event.preventDefault();

            removeRegion();
            setTimeout(() => {
              router.push(getPageRoute("main", null, null));
            }, 100);
          }}
          className="text-center text-xs font-medium text-accentBlue hover:underline"
        >
          {t("regions.changeRegion")}
        </UnstyledButton>
      </div>
    </>
  );
};

export default LoginForm;
