import { useMutation } from "react-query";
import { useRouter } from "next/router";

import axios from "axios";

import apiRoutes from "@/utils/constants/apiRoutes";
import { getRegionBySlug } from "@/utils/regions/regionsConfig";

export const useReVerify = () => {
  const router = useRouter();

  const regionConfig = getRegionBySlug(
    `${String(router?.query?.region)}` ?? "nl",
  );

  return useMutation((payload: { hash: string }) =>
    axios.post(`${regionConfig?.apiUrl}${apiRoutes.client.reVerify}`, {
      hash: payload.hash,
    }),
  );
};
