import React from "react";
import { type GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import LoginForm from "@/components/login/LoginForm";
import { Divider, LanguageSelect } from "@/components/ui";
import LogoBlack from "@/components/ui/Logo";
import PreLoginPage from "@/components/wrappers/PreLoginPage";
import { getLanguages, useGetLanguageByKey } from "@/utils/i18n/languages";
import { staticPaths } from "@/utils/i18n/staticFormatter";

import { type getStaticGeneratedProps } from "@/types";

const LoginPage = ({ lang }: getStaticGeneratedProps) => {
  const { t } = useTranslation();

  const getLanguageByKey = useGetLanguageByKey();
  const languages = getLanguages();
  const defaultLanguage = getLanguageByKey(lang);
  const router = useRouter();
  const currentLanguage = languages.find(
    (e) => e.i18n === String(router?.query?.lang),
  );

  return (
    <PreLoginPage>
      <div className="mx-auto flex max-w-[364px] flex-col md:max-w-[432px] lg:max-w-[415px]">
        <div className="mx-auto mb-16">
          <LogoBlack width={110} />
        </div>
        <LanguageSelect
          label={t("globals.language")}
          page="login"
          className="mb-12"
          defaultLanguage={defaultLanguage}
          languages={languages}
          currentLanguage={currentLanguage}
        />
        <Divider />
        <h1
          data-testid="login-title"
          className="mb-4 mt-14 text-center text-3xl"
        >
          {t("login.v2.title")}
        </h1>
        <p className="mb-8 text-center">{t("login.v2.description")}</p>
        <LoginForm />
      </div>
    </PreLoginPage>
  );
};

export default LoginPage;

export async function getStaticPaths() {
  return {
    paths: staticPaths,
    fallback: true,
  };
}

export const getStaticProps: GetStaticProps = async ({ params }) => ({
  props: {
    region: params?.region,
    lang: params?.lang,
    ...(await serverSideTranslations(params?.lang as string)),
  },
});
